<template>
  <div class="share-container -scrollable text">
    <p>
      {{ l.share.link_info }}
    </p>
    <b>Mail</b>
    <br/>
    <div class="p hstack">
      <input
        class="input -fit"
        type="email"
        ref="input"
        placeholder="Adresse mail"
      />
      <sea-button id="sendButtonEmail" @action="doShareMail">{{ l.share.button_copy }}</sea-button>
    </div>
    <div style="display: flex;color:#CC2222;font-weight:600;display:none"  ref="alertEmail" id="alertEmail">Email incorrect, merci de donner un email du type : "support@a2display.fr"</div>
    <div style="display: flex;justify-content: center;font-size:2rem;">ou</div>
    <br/>
    <b>SMS</b>
    <div class="p hstack">
      <input
        class="input -fit"
        type="text"
        ref="input2"
        placeholder="Numéro de téléphone"
      />
      <sea-button id="sendButtonSMS" @action="doSharePhone">{{ l.share.button_copy }}</sea-button>
    </div>
    <div style="display: flex;color:#CC2222;font-weight:600;display:none"  ref="alertSMS" id="alertSMS">Numéro incorrect, merci de donner un numéro du type : "0604030201"</div>
    <p>{{ l.share.qr_info }}</p>
    <p class="qrcode" v-html="qrcode">QRCode</p>
    <p v-html="l.share.feedback"></p>
  </div>
</template>

<style lang="scss">
.share-container {
  p,
  .p {
    margin-bottom: 0.1rem;
    margin-top:0.5rem;
  }

  .input {
    border: 1px solid gray;
    background: white;
    padding: 0.5rem;
    border-radius: 0.25rem;
    width: 100%;
    color: #1e89f6;
    margin-right: 0.5rem;
  }

  .qrcode {
    max-width: 50vh;
  }
}
</style>

<script>
import { qrcode } from "../lib/qrcode"
import { createLinkForRoom, shareLink } from "../lib/share"
import SeaButton from "../ui/sea-button"
import { API_URL } from "../config"

const log = require("debug")("app:app-share")

export default {
  name: "app-share",
  components: { SeaButton },
  data() {
    return {
      url: "",
      qrcode: "",
    }
  },
  methods: {
    selectAll() {
      setTimeout(() => {
        let el = this.$refs.input
        el.select()
      }, 0)
    },
    doShareMail() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          {recipient:this.$refs.input.value,
          room:createLinkForRoom(this.state.room)
        })
      };
      this.$refs.alertEmail.style.display="none";
      const validateEmail=(email)=> {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }
      if(validateEmail(this.$refs.input.value)){
        document.getElementById("sendButtonEmail").style.backgroundColor="#11AA11";
        document.getElementById("sendButtonEmail").innerHTML="Mail Envoyé !";
        console.log(API_URL)
        fetch(API_URL+"/mail/visio",requestOptions)
        .then(data => {
            data.text().then(
                text => {
                  let error=false;
                  setTimeout(()=>{
                    document.getElementById("sendButtonEmail").style.backgroundColor="#007ed9";
                    document.getElementById("sendButtonEmail").innerHTML="Envoyer";
                  },3000)
                  
                  try{
                    if(JSON.parse(text)["status"] == "ko"){
                      error=true;
                    }                    
                  }
                  catch(e){
                    error=true;
                  }
                  if(error == true){
                    this.$refs.alertEmail.style.display="block";
                    setTimeout(()=>{this.$refs.alertEmail.style.display="none"},10000)
                  }
                  else{
                    this.$refs.input.value="";
                  }
                }
            )
        })
        .catch(error => {
            this.$refs.input.value="";
            console.error(error);
        });

      }
      else{
        this.$refs.alertEmail.style.display="block";
        setTimeout(()=>{this.$refs.alertEmail.style.display="none"},10000)
      }
    },
    doSharePhone() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          {phoneNumber:this.$refs.input2.value,
          room:createLinkForRoom(this.state.room)
        })
      };
      this.$refs.alertSMS.style.display="none";
      const validateSms=(sms)=>{
        const regex=/[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/gm
        return regex.test(sms)
      }
      if(validateSms(this.$refs.input2.value)){
        document.getElementById("sendButtonSMS").style.backgroundColor="#11AA11";
        document.getElementById("sendButtonSMS").innerHTML="SMS Envoyé !";
        if(window.sendSms!=null){
          window.sendSms(API_URL,requestOptions)
          .then(data => {
              data.text().then(
                  text => {
                    let error=false;
                    setTimeout(()=>{
                      document.getElementById("sendButtonSMS").style.backgroundColor="#007ed9";
                      document.getElementById("sendButtonSMS").innerHTML="Envoyer";
                    },3000)
                    
                    try{
                      if(JSON.parse(text)["status"] == "ko"){
                        error=true;
                      }                    
                    }
                    catch(e){
                      error=true;
                    }
                    if(error == true){
                      this.$refs.alertSMS.style.display="block";
                      setTimeout(()=>{this.$refs.alertSMS.style.display="none"},10000)
                    }
                    else{
                      this.$refs.input2.value="";
                    }
                  }
              )
          })
          .catch(error => {
              alert("Error lors de l'envoi du SMS")
              console.error(error);
          });
        }
        else{
          console.error("a2desktop not detected")
        }
      }
      else{
        this.$refs.alertSMS.style.display="block";
        setTimeout(()=>{this.$refs.alertSMS.style.display="none"},10000)
      }
    },
  },
  async mounted() {
    this.url = createLinkForRoom(this.state.room)
    const typeNumber = 0
    const errorCorrectionLevel = "H"
    const qr = qrcode(typeNumber, errorCorrectionLevel)
    qr.addData(this.url)
    qr.make()
    this.qrcode = qr.createSvgTag({
      scalable: true,
    })
  },
}
</script>
